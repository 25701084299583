import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Col, Row } from "react-bootstrap";

import { DashboardTab1, SourceIcon, UapplyLogo, UlearnIcon } from "../Assets";

type LeadsComponentProps = {
  qualified: any;
  notQualified: any;
  studentUapply: any;
  studentUlearn: any;
  studentUk: any;
  studentsOthers: any;
  top5Degree: any;
  top5Countries: any;
  studentNew: any;
  studentClosed: any;
  studentApplied: any;
  studentWa: any;
};
export const LeadsComponent: React.FC<LeadsComponentProps> = ({
  qualified,
  notQualified,
  studentUapply,
  studentUlearn,
  studentUk,
  studentsOthers,
  top5Degree,
  top5Countries,
  studentWa,
  studentNew,
  studentClosed,
  studentApplied,
}) => {
  const data = {
    labels: ["UK", "Other"],
    datasets: [
      {
        data: [3254, 16000],
        backgroundColor: ["#FFB7C4", "#9F7DEC"],
        hoverBackgroundColor: ["#FFB7C4", "#9F7DEC"],
        borderWidth: 1,
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
        },
      },
      datalabels: {
        display: true,
        color: "#fff",

        font: {
          size: "16",
        },
      },
    },
    cutout: "85%",
  };

  function getCountryEmoji(
    countryCode: string | undefined
  ): string | undefined {
    const OFFSET = 127397;
    const codePoints: number[] | undefined = countryCode
      ?.toUpperCase()
      .split("")
      .map((char: string) => char.charCodeAt(0) + OFFSET);
    return codePoints ? String.fromCodePoint(...codePoints) : undefined;
  }

  return (
    <Container>
      <>
        <Row>
          <Col md={4}>
            <Card>
              <Header>
                <Title>Leads</Title>
                <Percentage>
                  {`${((qualified / (notQualified + qualified)) * 100).toFixed(
                    2
                  )}%` || "0%"}
                  <DashboardTab1 style={{ marginLeft: 8 }} selected={true} />
                </Percentage>
              </Header>
              <Row>
                <Col md={6}>
                  <LeadType>
                    <Text>👍 Qualified</Text>
                    <Number>{qualified}</Number>
                    {/* <PercentageChange>+24% ↑ vs Yesterday</PercentageChange> */}
                  </LeadType>
                </Col>
                <Col md={6}>
                  <LeadType>
                    <Text>🖐️ Not Qualified</Text>
                    <Number>{notQualified}</Number>
                    {/* <PercentageChange>+58 % ↑ vs Yesterday</PercentageChange> */}
                  </LeadType>
                </Col>
              </Row>
              <SubSection>
                <SubTitle>Qualified Leads Nationality</SubTitle>
                <NationalityList>
                  {top5Countries.map((con: any, key: any) => (
                    <Nationality key={key}>
                      <Flag>{getCountryEmoji(con.countryCode)}</Flag>
                      <CountryName>{con._id}</CountryName>
                      <Change>{con.count} </Change>
                    </Nationality>
                  ))}
                </NationalityList>
              </SubSection>
            </Card>
          </Col>
          <Col md={4}>
            {" "}
            <Card>
              <Header>
                <Title>Source</Title>
                <SourceIcon />
              </Header>
              <Row>
                <Col md={6}>
                  <LeadType>
                    <SourceName>
                      <UapplyLogo style={{ marginRight: 4 }} /> Uapply
                    </SourceName>
                    <Number>{studentUapply}</Number>
                    {/* <PercentageChange>-24% ↓ vs Yesterday</PercentageChange> */}
                  </LeadType>
                </Col>
                <Col md={6}>
                  <LeadType>
                    <SourceName>
                      <UlearnIcon style={{ marginRight: 4 }} /> Ulearn
                    </SourceName>
                    <Number>{studentUlearn}</Number>
                    {/* <PercentageChange>+58% ↑ vs Yesterday</PercentageChange> */}
                  </LeadType>
                </Col>
              </Row>
              <SubSection>
                <SubTitle>Qualified Lead Status</SubTitle>
                <StatusList>
                  <Nationality>
                    <Icon>🔥</Icon>
                    <CountryName>New:</CountryName>
                    <Change>{studentNew}</Change>
                  </Nationality>

                  <Nationality>
                    <Icon>📧</Icon>
                    <CountryName>Sent on whatsapp</CountryName>
                    <Change>{studentWa}</Change>
                  </Nationality>

                  <Nationality>
                    <Icon>💪</Icon>
                    <CountryName>Applied</CountryName>
                    <Change>{studentApplied}</Change>
                  </Nationality>

                  <Nationality>
                    <Icon>🔒</Icon>
                    <CountryName>Closed</CountryName>
                    <Change>{studentClosed}</Change>
                  </Nationality>
                </StatusList>
              </SubSection>

              <SubSection>
                <LeadType
                  className="d-flex justify-content-between"
                  style={{ padding: "8px 16px" }}
                >
                  <div>
                    {(
                      (studentUlearn / (studentUapply + studentUlearn)) *
                      100
                    ).toFixed(2)}
                    %
                    <br />
                    <LogoText>
                      <UlearnIcon style={{ width: 12, height: 12 }} /> Ulearn
                    </LogoText>
                  </div>
                  <div>
                    {(
                      (studentUapply / (studentUapply + studentUlearn)) *
                      100
                    ).toFixed(2)}
                    %
                    <br />{" "}
                    <LogoText>
                      <UapplyLogo style={{ width: 12, height: 12 }} /> Uapply
                    </LogoText>
                  </div>
                </LeadType>
              </SubSection>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <>
                <Header>
                  <Title>Destination</Title>
                  <SourceIcon />
                </Header>
                <div
                  className="d-flex justify-content-center"
                  style={{ position: "relative" }}
                >
                  <PieContainer>
                    <Doughnut
                      data={data}
                      options={options}
                      // plugins={[ChartDataLabels as any]}
                    />
                  </PieContainer>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "50%",
                      transform: "translate(-50%, -50%)" /* Add this line */,
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <GraphLabel>UK</GraphLabel>
                      <Number style={{ margin: 0 }}>{studentUk}</Number>
                    </div>
                    <Border />
                    <div className="d-flex justify-content-between align-items-center">
                      <GraphLabel>Others</GraphLabel>
                      <Number style={{ margin: 0 }}>{studentsOthers}</Number>
                    </div>
                  </div>
                </div>
                <SubSection>
                  <SubTitle>Qualified Leads Degree</SubTitle>
                  <DegreeList>
                    {top5Degree.map((stu: any, key: any) => (
                      <Degree key={key}>
                        <Icon>
                          {" "}
                          {stu._id === "Bachelor's"
                            ? "👦"
                            : stu._id === "Master's"
                              ? "👨‍🦰"
                              : "‍‍👨‍🦳"}
                        </Icon>
                        <DegreeName>{stu._id}</DegreeName>
                        <Change>{stu.count}↑</Change>
                      </Degree>
                    ))}
                  </DegreeList>
                </SubSection>
              </>
            </Card>
          </Col>
        </Row>
      </>
      <br />
      <br />
      <br />
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid #f2f6fb;
`;
const Border = styled.div`
  border-bottom: 1px solid #f2f6fb;
  margin: 19px 0;
`;
const Title = styled.h2`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #303030;
  margin-bottom: 0;
`;

const LogoText = styled.h2`
  color: var(--Font-2, rgba(30, 36, 50, 0.8));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
const GraphLabel = styled.h2`
  color: rgba(30, 36, 50, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
const Percentage = styled.span`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: right;
  color: #1c1c1c;
  display: flex;
  align-items: center;
`;

const LeadType = styled.div`
  text-align: left;
  border-radius: 12px;
  border: 1px solid var(--Neutro-Blue, #f2f6fb);
  background: #fff;
  padding: 16px;
`;

const Text = styled.div`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #1e2432;
`;

const Number = styled.div`
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  margin-bottom: 16px;
  font-weight: 700;
  color: #1e2432;
  margin-top: 5px;
`;

// const PercentageChange = styled.div`
//   font-size: 12px;
//   color: #888;
// `;

const SubSection = styled.div`
  margin-top: 24px;
`;

const SubTitle = styled.h3`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #303030;
  margin-bottom: 24px;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid #f2f6fb;
`;

const NationalityList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
`;

const Nationality = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;

const Flag = styled.span`
  font-size: 18px;
`;

const CountryName = styled.span`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(30, 36, 50, 0.8);
  margin-left: 10px;
  flex: 1;
`;

const Change = styled.span`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: #1e2432;
`;

// const SourceInfo = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const SourceType = styled.div`
//   text-align: center;
// `;

const SourceName = styled.div`
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  color: #1e2432;
`;

const StatusList = styled.ul`
  list-style: none;
  padding: 0;
`;

const Icon = styled.div`
  font-size: 18px;
`;

const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const DegreeList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
`;

const Degree = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;

const DegreeName = styled.span`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(30, 36, 50, 0.8);
  margin-left: 10px;
  flex: 1;
`;
