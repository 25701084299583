import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { generateQR } from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
// import { TextArea } from "../TextArea";
import { FormInput } from "../FormInput";

type Props = {
  show: any;
  handleClose: any;
  reload: any;
};

export const UploadModal = (props: Props) => {
  interface Values {
    url: string;
  }
  const [loading, setloading] = useState(false);
  const [qrData, setqrData] = useState<any>("");

  // function addPrefixAndCommas(numbers: any[], countryCode: any) {
  //   const prefixedNumbers = numbers.map((num: any) => {
  //     return { phoneNumber: `${countryCode}${num}`, status: "not sent" };
  //   });
  //   return prefixedNumbers;
  // }

  const copyTextToClipboard = (textToCopy: any) => {
    navigator.clipboard
      .writeText(textToCopy.split(" ")[0])
      .then(() => {
        alert("Text has been copied to the clipboard");
      })
      .catch((err) => {
        console.error("Unable to copy!", err);
      });
  };

  // function shortenText(text: string) {
  //   return text.length > 25 ? text.slice(0, 25) + "..." : text;
  // }
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="modal fade"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Generate QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              url: "",
            }}
            onSubmit={async (
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              setloading(true);

              const res = await generateQR(values.url);
              setqrData(res.qrCodeImage);
              values.url = "";
              // const formatedNumbers = addPrefixAndCommas(
              //   values.numbers.split("\n").map(Number),
              //   selectedCountry.code
              // );

              // for (let index = 0; index < formatedNumbers.length; index++) {
              //   const element = formatedNumbers[index];
              //   await uploadNumbers(element);
              // }
              // props.reload();

              setloading(false);
            }}
          >
            <Form style={{ width: "70%" }} className="d-flex flex-column">
              {/* <Dropdown style={{ marginBottom: 20 }}>
                <Dropdown.Toggle>
                  {selectedCountry?.name || "Select Country"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {countries.map((a: any, key: any) => (
                    <Dropdown.Item
                      key={key}
                      onClick={() => {
                        setselectedCountry(a);
                      }}
                      eventKey="1"
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
              {/* <TextArea
                id={"numbers"}
                name={"numbers"}
                placeholder={""}
                type={""}
              /> */}
              <FormInput
                id="url"
                name="url"
                placeholder="https://www.google.com/"
                type="url"
                label="URL"
              />

              {qrData ? (
                <div
                  style={{
                    cursor: "copy",
                    color: "#7F56D9",
                    fontSize: 13,
                    fontWeight: 700,
                    marginBottom: 20,
                  }}
                  onClick={() => copyTextToClipboard(qrData)}
                >
                  Click for QR
                </div>
              ) : null}
              <div className="d-flex flex-row">
                <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  disabled={loading}
                  label="Submit"
                />
                <ButtonComponent
                  btnType="light"
                  onClick={() => props.handleClose()}
                  loading={loading}
                  disabled={loading}
                  style={{ marginLeft: 10 }}
                  label="Close"
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
